import { ItemListContainer } from "../ItemListContainer/ItemListContainer"
import Hero from "../Hero/Hero"
const Home = () => {
    return (
        <>
            <Hero/>
            <ItemListContainer/>
        </>
    )
}
export default Home